import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import KanbanButton from './components/kanban_button';
import './App.css';

function App() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("https://kanbandata.utskot.no/data")
    .then(res => res.json())
    .then((result) => {
      setData(result);
      setIsLoaded(true);
    },
    (error) => {
      setError(error);
      console.error(error.message);
    });
  }, []);

  if(error) {
    return (
      <Container className='p-4'>
        <Alert key='danger' variant='danger'>{error.message}</Alert>
      </Container>
    )
  } else if(isLoaded) {
  return (
    <Container className='p-4' fluid>
      <Row>
        <Col>
          <h1 className='text-center'>{data?.day_title}</h1>
        </Col>
        <Col>
          <h1 className='text-center'>{data?.night_title}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          {data?.day?.map((item, idx) => (
            <KanbanButton key={idx} title={item.task} content={item.desc} state={item.state}></KanbanButton>
          ))}
        </Col>
        <Col>
          {data?.night?.map((item, idx) => (
            <KanbanButton key={idx} title={item.task} content={item.desc} state={item.state}></KanbanButton>
          ))}
        </Col>
      </Row>
    </Container>
  );
  } else {
    return (
      <Container className='p-4 text-center'>
        <Spinner animation="border" variant="primary" />
      </Container>
    )
  }
}

export default App;
