import React from 'react';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import './kanban_button.css';

class KanbanButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          title: (props.title) ? props.title : 'Udefinert',
          content: (props.content) ? props.content : 'Udefinert',
          state: (props.state) ? props.state : false,
          info: false
        };

        this.setButtonState = this.setButtonState.bind(this);
        this.toggleState = this.toggleState.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.closeInfo = this.closeInfo.bind(this);
      }

    setButtonState(state) {
        this.setState({state: state});
    }

    toggleState() {
        this.setState((prevState, props) => ({
            ...prevState,
            state: !prevState.state
        }))
    }

    showInfo() {
        this.setState({info: true});
    }

    closeInfo() {
        this.setState({info: false});
    }

    render() {
        return <div>
            <Modal className='text-black' size='lg' centered show={this.state.info} onHide={this.closeInfo}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>{this.state.content}</p>
                </Modal.Body>

                <Modal.Footer className='text-right'>
                    <Button variant="secondary" onClick={this.closeInfo}>Lukk</Button>
                </Modal.Footer>
            </Modal>

            <InputGroup size='lg' className={`mb-2 kanban-button ${this.state.state ? "checked" : "unchecked"}`}>
                <Button className={`kanban-title`} size='lg' onClick={this.toggleState}>{this.state.title}</Button>
                <Button className='kanban-info' size='lg' onClick={this.showInfo}>?</Button>
            </InputGroup>
        </div>
    }
}

export default KanbanButton;